import type { FC } from "react";
import React, { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { featureFlags } from "src/lib/featureFlags";
import {
  formatPhoneNumberNational,
  validatePhoneNumber
} from "src/lib/formatPhoneNumber";
import translate from "src/lib/translate";
import ProfileCubit from "src/state/ProfileCubit/ProfileCubit";
import { useBloc } from "src/state/state";
import AppPageTitle from "src/ui/components/AppPageTitle/AppPageTitle";
import ChangeEmail from "src/ui/components/ChangeEmail/ChangeEmail";
import ChangeNotificationNumber from "src/ui/components/ChangeNotificationNumber/ChangeNotificationNumber";
import ChangePassword from "src/ui/components/ChangePassword/ChangePassword";
import Data from "src/ui/components/Data/Data";
import { PROFILE_URL } from "src/ui/components/ProfileOverview/ProfileOverview";
import OverlayBackButton from "src/ui/components/StyledComponents/OverlayBackButton";
import BiometricAuthenticationSetup from "../BiometricAuthenticationSetup/BiometricAuthenticationSetup";
import TwoFactorAuthenticationSetup from "../TwoFactorAuthenticationSetup/TwoFactorAuthenticationSetup";

const accountPath = "/app/profile/account";

enum AccountInformationAction {
  changeEmail = "changeEmail",
  changePassword = "changePassword",
  changePhoneNumber = "changePhoneNumber"
}

const AccountInformation: FC = () => {
  const [profile, { loadData, clearAllData }] = useBloc(ProfileCubit);
  const navigate = useNavigate();
  const { action = "" } = useParams<{ action?: AccountInformationAction }>();

  const title = useMemo(() => {
    switch (action) {
      case AccountInformationAction.changeEmail:
        return translate("account.title.editEmailAddress");
      case AccountInformationAction.changePassword:
        return translate("account.title.editPassword");
      case AccountInformationAction.changePhoneNumber:
        return translate("account.title.editPhoneNumber");
      default:
        return translate("account.title.default");
    }
  }, [action]);

  const backUrl = useMemo(() => {
    switch (action) {
      case AccountInformationAction.changeEmail:
      case AccountInformationAction.changePassword:
      case AccountInformationAction.changePhoneNumber:
        return accountPath;
      default:
        return PROFILE_URL;
    }
  }, [action]);

  useEffect(() => {
    clearAllData();
    void loadData();
  }, []);

  const goto = (goToAction: AccountInformationAction) => () => {
    navigate(`${accountPath}/${goToAction}`);
  };

  const phoneNumber = profile.data.number?.number;
  const pnoneNumberValid = phoneNumber
    ? validatePhoneNumber(phoneNumber)
    : true;
  const formattedPhoneNumber = formatPhoneNumberNational(phoneNumber, false, {
    noValidation: true
  });

  return (
    <>
      <OverlayBackButton show fallbackUrl={backUrl} />
      <AppPageTitle title={title}></AppPageTitle>
      <nine-spacer s={"xl"}></nine-spacer>

      {action === "" && (
        <>
          <Data.Table
            title={translate("email")}
            onAction={goto(AccountInformationAction.changeEmail)}
          >
            <Data.Row data={profile.data.email} />
          </Data.Table>

          <Data.Table
            title={translate("password")}
            onAction={goto(AccountInformationAction.changePassword)}
          >
            <Data.Row data={"************"} />
          </Data.Table>

          <Data.Table
            title={
              <>
                {translate("phoneNumber")}
                {!pnoneNumberValid && (
                  <nine-pill
                    style={{
                      marginLeft: "var(--space-xs)",
                      "--background-color": "var(--color-sunrise-orange)"
                    }}
                  >
                    {translate("info.number_invalid")}
                  </nine-pill>
                )}
              </>
            }
            onAction={goto(AccountInformationAction.changePhoneNumber)}
          >
            <Data.Row data={formattedPhoneNumber} />
          </Data.Table>

          {featureFlags.flagMultiFactorAuth && (
            <>
              <BiometricAuthenticationSetup />

              <Data.Section
                title={translate("account.title.twoFactorAuthentication")}
              >
                <TwoFactorAuthenticationSetup />
              </Data.Section>
            </>
          )}
        </>
      )}

      {action === AccountInformationAction.changeEmail && <ChangeEmail />}
      {action === AccountInformationAction.changePassword && <ChangePassword />}
      {action === AccountInformationAction.changePhoneNumber && (
        <ChangeNotificationNumber />
      )}
    </>
  );
};

export default AccountInformation;
